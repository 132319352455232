/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-zr6kr0 js-anim  --anim7 --anim-s5 --full pb--25" anim={"7"} name={"uvod"} animS={"5"} style={{"minHeight":"100vh"}} fullscreen={true} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/21041/8dbf8b9139c0488594e1e062a115d4b7_bri=85__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/21041/8dbf8b9139c0488594e1e062a115d4b7_bri=85__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/21041/8dbf8b9139c0488594e1e062a115d4b7_bri=85__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/21041/8dbf8b9139c0488594e1e062a115d4b7_bri=85__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/21041/8dbf8b9139c0488594e1e062a115d4b7_bri=85__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/21041/8dbf8b9139c0488594e1e062a115d4b7_bri=85__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/21041/8dbf8b9139c0488594e1e062a115d4b7_bri=85__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/21041/8dbf8b9139c0488594e1e062a115d4b7_bri=85__s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5" anim={"6"} animS={"5"} style={{"maxWidth":1129}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/21041/95e4af1043de45c1a45a1dcef665b1d1.svg"} sizes={"100vw"} style={{"maxWidth":458}} srcSet={""}>
              </Image>

              <Title className="title-box fs--185 w--900 title-box--invert ls--006 mt--06 pt--12" content={"<span style=\"color: rgb(247, 247, 247);\">PF 2022</span><br>"}>
              </Title>

              <Text className="text-box fs--30 w--600 ls--004 mt--10" content={"www.plajner.cz"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}