import React from 'react'
import { ThemeProvider } from '@emotion/react'

const theme = {
  "id": 112,
  "colors": {
    "colorVariable1": "rgba(250,170,65,1)",
    "colorVariable2": "rgba(250,170,65,1)",
    "colorVariableBtn1": "rgba(10,25,40,1)",
    "colorVariableBtn2": "rgba(255,255,255,1)",
    "front1": "rgba(121,121,121,1)",
    "front2": "rgba(255,255,255,1)",
    "back2": "rgba(0,0,0,1)"
  },
  "variables": {
    "color-variable-1": "rgba(250,170,65,1)",
    "color-variable-2": "rgba(250,170,65,1)",
    "color-variable-btn-1": "rgba(10,25,40,1)",
    "color-variable-btn-2": "rgba(255,255,255,1)"
  },
  "globalMobile": "27em",
  "meta": []
}

export default function ThemeWrapper(props) {
  return (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  )
}